<template>
  <div>
    <Permission/>
    <Breadcrumb />
    <main class="pt-5">
      <section class="pb-4">
        <div v-if="noDistributionProperties">
          <h3 class="section-title has-text-danger has-text-weight-semibold py-2">No settled property available for distribution</h3>
        </div>
        <div v-else>
          <h3 class="section-title has-text-weight-semibold py-2">Distribute funds from the selected property to the members</h3>
          <b-select v-model="selectedProperty" required>
            <option
              v-for="(property, index) in distributionProperties"
              :key="index"
              :value="property"
            >{{ property.name }}</option>
          </b-select>
        </div>
      </section>
      <section class="step-1 panel py-4 px-3">
        <h3 class="section-title has-text-weight-semibold py-2">Step 1: Fund Distribution</h3>
        <p>Add the funds to be distributed using the connected bank account into the Wallet here. This may take 5-7 business days.</p>
        <b class="has-text-weight-semibold py-4 is-block">Wallet Balance</b>
        <p class="has-text-weight-semibold wallet-balance has-text-blue mb-4">{{ balance | truncated | formatCurrency }}</p>
        <b-button type="is-blue is-rounded" @click="toAddFunds">ADD FUNDS</b-button>
      </section>
      <section class="step-2 panel py-4 px-3">
        <h3 class="section-title has-text-weight-semibold py-2">Step 2: Confirm Distribution</h3>
        <p>
          Enter the amount to be distributed below.
          Click Download to Review to receive a CSV file with the distribution amounts for each members's share certificate in the property entity for review.
        </p>
        <ul class="my-5">
          <li v-for="(source, key) in sources" :key="key" class="fund-source is-inline-block px-3 mb-3">
            <b-field :label="parseText(key)" custom-class="is-capitalized">
              <b-input
                v-model.number="sources[key]"
                type="number"
                min="0"
                step="0.01"/>
            </b-field>
          </li>
        </ul>
        <b-button type="is-blue is-rounded" :disabled="!csvDownloadEnabled" :loading="isDownloadingCsv" @click="onCsvDownload">DOWNLOAD FOR REVIEW</b-button>
      </section>
      <section class="step-3 panel py-4 px-3">
        <h3 class="section-title has-text-weight-semibold py-2">Step 3: Payout</h3>
        <p>
          After confirming the distribution amounts, if the Wallet balance is sufficient for the Distribution amount, you can click Distribute for initiating the payout.
          The distribution will be to two decimal places and the fractional amounts are tracked.
        </p>
        <b class="has-text-weight-semibold py-4 is-block">Distribution Amount</b>
        <p class="has-text-weight-semibold wallet-balance has-text-blue mb-4">{{ totalAmount | formatCurrency }}</p>
        <b-button type="is-blue is-rounded" :disabled="distributeButtonDisabled" :loading="isDistributing" @click="onDistribution">DISTRIBUTE</b-button>
      </section>
    </main>
    <ConfirmModal
      text="Fund is being processed!"
      buttonText="Got it"
      :showModal="showConfirmModal"
      :hasCloseIcon="false"
      @closeModal="toDistributionsPage"
    >
    </ConfirmModal>
  </div>
</template>
<script>
import { truncate } from '@utils/common-methods/numberFormatter'
import { downloadFileFromUrl, camelToSnakeCase } from '@utils/common-methods/common'
import Permission from '@views/addy_plus/base/Permission.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import { fetchWalletDetails, fetchDistributionCsv, distributeFunds, fetchDistributionProperties } from '@api/addy-plus/wallet'

export default {
  components: {
    Permission,
    Breadcrumb,
    ConfirmModal,
  },
  data() {
    return {
      balance: 0,
      sources: {
        rentalIncome: 0,
        returnOfCapital: 0,
        capitalGains: 0,
        businessIncome: 0,
      },
      showConfirmModal: false,
      isDownloadingCsv: false,
      isDistributing: false,
      distributionProperties: [],
      selectedProperty: null,
    }
  },
  computed: {
    csvDownloadEnabled() {
      return Object.values(this.sources).some((source) => source > 0)
    },
    totalAmount() {
      return Object.values(this.sources).reduce((pre, cur) => pre + cur, 0)
    },
    noSufficientFund() {
      return this.balance < this.totalAmount
    },
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    distributeButtonDisabled() {
      return !this.selectedProperty || !this.totalAmount || this.noSufficientFund
    },
    noDistributionProperties() {
      return this.distributionProperties.length === 0
    },
  },
  created() {
    this.getWalletBalance()
    this.getDistributionProperties()
  },
  methods: {
    getWalletBalance() {
      fetchWalletDetails(this.corporationId)
        .then((response) => {
          if (!response.success) return
          this.balance = response.data.walletBalance
        })
    },
    getDistributionProperties() {
      fetchDistributionProperties(this.corporationId)
        .then((response) => {
          if (!response.success) return
          this.distributionProperties = response.data.properties
          if (!this.noDistributionProperties) this.selectedProperty = this.distributionProperties[0]
        })
    },
    toAddFunds() {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet/funds`)
    },
    onCsvDownload() {
      this.isDownloadingCsv = true
      const payload = this.parseDistributionPayload()
      fetchDistributionCsv(this.corporationId, payload).then((response) => {
        const urlCreator = window.URL || window.webkitURL
        const url = urlCreator.createObjectURL(response)
        downloadFileFromUrl(url, 'distribution-overview.csv')
        this.isDownloadingCsv = false
      })
    },
    toDistributionsPage() {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/distributions`)
    },
    parseText(camelCase) {
      return camelCase.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`)
    },
    onDistribution() {
      this.isDistributing = true
      const payload = this.parseDistributionPayload()
      distributeFunds(this.corporationId, payload).then((response) => {
        this.isDistributing = false
        if (!response.success) return
        this.showConfirmModal = true
      })
    },
    parseDistributionPayload() {
      const payload = {}
      Object.keys(this.sources).forEach((key) => {
        payload[camelToSnakeCase(key)] = this.sources[key]
      })
      payload.property_id = this.selectedProperty.id
      return payload
    },
  },
  filters: {
    truncated(number) {
      return truncate(number)
    },
    formatCurrency(value) {
      const number = Number(value)
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.section-title {
  font-size: 18px;
}
.panel {
  border-radius: 6px;
  .wallet-balance {
    font-size: 18px;
  }
  .fund-source {
    width: 50%;
  }
}
</style>
